body {
  margin: 0;
  padding: 0;
}
img {
  display: block;
}
.box1,
.box2 {
  width: 100vw;
}
.box1 {
  position: relative;
  height: 46vw;
  background: url("../assets/Frame.png") no-repeat center center;
  background-size: 100% 100%;
  overflow: hidden;
}
.box1 img.logo {
  width: 4vw;
  margin: 8vw auto 0;
}
.box1 .name {
  margin: 1vw auto 0;
  width: 5vw;
  font-size: 1.3vw;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: #fff;
  text-align: center;
}
.box1 .title {
  margin: 4vw auto 0;
  width: 25vw;
  font-size: 2.5vw;
  font-family: SFNSDisplay;
  color: #fff;
  letter-spacing: 2px;
  text-align: center;
}
.box1 img.app-store {
  margin: 8vw auto;
  width: 10vw;
}

.box1 .mouse-icon {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 5%;
}
.box1 > img,
.box2 > img {
  width: 100%;
}
.box-mobile,
.download-btn {
  display: none;
}
.box3 {
  display: flow-root;
  text-align: center;
  padding: 2% 0 5%;
}
.box3 p a {
  color: #000;
}
.box3 p a:not(:last-child) {
  margin-right: 14%;
}
.box3 p span {
  position: relative;
  display: inline-block;
  padding-bottom: 10px;
  cursor: pointer;
}
.box3 p span:after {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 3px;
  background: #000;
  transition: width 0.3s;
}
.box3 p span:hover:after,
.box3 p span.active:after {
  width: 4em;
}
.box3 .content {
  font-size: 12px;
  color: #333333;
  width: 50%;
  height: 400px;
  margin: 4% auto;
  padding: 20px 40px;
  border: 1px solid #979797;
  text-align: left;
  box-sizing: border-box;
  overflow: auto;
}
.box3 .content a {
  text-decoration: none;
}
.dowload-btn {
  display: none;
}
footer {
  margin: 0;
  width: 100vw;
  font-size: 16px;
  background: #000;
  color: #fff;
  text-align: center;
  display: flow-root;
  padding: 5% 0 2%;
}
footer a {
  text-decoration: none;
  color: #fff;
}
footer p a:not(:last-child) {
  margin-right: 14%;
}
footer p.copyright {
  color: #808080;
  font-size: 7px;
  margin-top: 5%;
}

@media screen and (max-width: 750px) {
  .box1 {
    height: 55vw;
  }
  .box1 img.logo {
    width: 15vw;
    margin: 10vw auto 0;
  }
  .box1 .name {
    margin: 1vw auto 0;
    width: 15vw;
    font-size: 5vw;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    color: #fff;
    text-align: center;
  }
  .box1 .title {
    margin: 6vw auto 0;
    width: 80vw;
    font-size: 6vw;
    font-family: SFNSDisplay;
    color: #fff;
    letter-spacing: 1px;
    text-align: center;
  }
  .box1 img.app-store,
  .box1 .mouse-icon,
  .box2,
  footer {
    display: none;
  }
  .box-mobile {
    width: 100vw;
    display: block;
  }
  .box-mobile img {
    width: 100%;
  }
  .download-btn {
    display: block;
  }
  .btn {
    display: block;
    margin: 10vw auto;
    padding: 2vw 0;
    width: 80vw;
    background: #ff3c36;
    color: #fff;
    text-align: center;
    font-size: 5vw;
    font-weight: 400;
    border-radius: 5vw;
    cursor: pointer;
    text-decoration: none;
  }
}

p.copyright a:hover {
  text-decoration: underline;
}

.copyright {
  color: #808080;
  font-size: 7px;
  text-align: center;
  background-color: #000;
  margin: 0;
  padding: 7px 0;
}

.copyright a {
  color: #808080;
  text-decoration: none;
}

.copyright a:hover {
  text-decoration: underline;
}

.copyright img {
  display: inline-block;
  width: 15px;
  vertical-align: top;
  margin-left: 10px;
  margin-right: 2px;
}

.down-box {
  display: flex;
  width: 25vw;
  margin: auto;
  justify-content: space-around;
}
img.app-store {
  display: block;
}
.download-btn {
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  padding-bottom: env(safe-area-inset-bottom);
}
.btn {
  width: 87.2vw;
  height: 13.7vw;
  border-radius: 7vw;
  margin: 4vw auto;
  font-size: 4vw;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-android {
  background-color: #7acb39;
}
.btn-apple {
  background-color: #ff3c36;
}
img.icon-store {
  display: block;
  width: 6.4vw;
  height: 6.4vw;
  margin-right: 2vw;
}
@media screen and (max-width: 750px) {
  body {
    padding-bottom: env(safe-area-inset-bottom);
  }
  .container {
    padding-bottom: 40vw;
  }
}
.about-us {
  position: relative;
}
.about-us-text {
  display: none;
  cursor: default;
  position: absolute;
  top: -120px;
  left: 0;
  transform: translateX(-40%);
  width: 20vw;
  height: 100px;
  background-color: #fff;
  color: #000;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 1vw;
}
.about-us-pop {
  height: 170px;
  top: -190px;
}
.about-us:hover .about-us-text {
  display: block;
}
.about-us-text i {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  width: 0px;
  height: 0px;
  border-top: 15px solid #fff;
  border-bottom: 15px solid transparent;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
}
.contact {
  cursor: pointer;
  color: #557fb9;
}
.pop {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 40px;
  line-height: 40px;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
}
.current-block {
  display: block;
}
.current-none {
  display: none;
}
